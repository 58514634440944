.New_Line_Height{
  line-height: 7px;
}
.product-img-container {
  max-width: 500px;
  max-height: 333px;
  /* max-width: 100%; */
}

.msg-bar {
  background-color: #ffffff;
}

.shortcut-btn {
  color: #7a6048;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: 0.5s;
}

.shortcut-btn:hover {
  color: #b58f6c;
  transition: 0.5s;
}

.msg-bar-text {
  font-weight: 500;
  font-size: 16px;
}

figure {
  width: 100%;
  background-repeat: no-repeat;
}

figure:hover img {
  opacity: 0;
}

.product-img {
  max-width: 500px;
  max-height: 333px;
  display: block;
  width: 100%;
  pointer-events: none;
}

.product-field-title {
  min-width: 180px;
}

/* ///////////////increase, decrease product///////////////// */

.quantity-field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
}

.quantity-field .val-btn {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 100%;
  padding: 0;
  background-color: #b58f6c;
  color: #ffffff;
  outline: none;
  cursor: pointer;
}

.quantity-field .dec-btn {
  margin-right: -4px;
}

.quantity-field .inc-btn {
  margin-left: -4px;
}

.quantity-field .qty-num {
  z-index: 3;
  display: inline-block;
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #b58f6c;
  margin: 0px;
  width: 40px;
  height: 100%;
  line-height: 40px;
  font-size: 11px;
  box-sizing: border-box;
  background: transparent;
}

.quantity-field .qty-num::selection {
  background: none;
}

/* ///////////////////////////////////////// */

@media only screen and (max-width: 600px) {
  .shortcut-btn {
    font-size: 11px;
  }
  .msg-bar-text {
    font-size: 11px;
  }

  .product-field-title {
    min-width: 143px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){

  .product-field-title {
    min-width: 143px;
  }
} 