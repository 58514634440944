.my-account-input {
  border-radius: 0px;
  background-color: transparent;
  border: 1px solid #494949;
  color: #b58f6c;
  font-size: 13px;
  height: 45px;
}

.my-account-input:focus {
  background-color: transparent;
  color: #b58f6c;
  border: 1px solid #494949;
  box-shadow: none;
}

.my-account-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d1ba9a;
  opacity: 1; /* Firefox */
}

.my-account-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d1ba9a;
}

.my-account-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d1ba9a;
}

.css-13cymwt-control {
  border: 1px solid #494949 !important;
  background-color: transparent !important;
  border-radius: 0px !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px;
}

.css-qbdosj-Input {
  background-color: transparent;
  border-radius: 0px;
  border: none;
  color: #b58f6c !important;
  margin: 0px;
}

.css-1dimb5e-singleValue {
  color: #b58f6c !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: #494949 !important;
}

.css-tj5bde-Svg {
  color: #b58f6c;
}

.css-1nmdiq5-menu {
  background-color: black;
  color: #b58f6c;
}
