.about-us-container {
  margin-top: 100px;
  margin-bottom: 150px;
}

.about-us-img {
  position: relative;
  height: 500px;
  width: 100%;
}

.about-us-text {
  line-height: 24px;
}

@media only screen and (min-device-width: 765px) and (max-device-width: 1024px) {
  .about-us-img {
    height: 300px;
    width: 100%;
  }
}

@media only screen and (max-width: 765px) {
  .about-us-container {
    margin-top: 40px;
    margin-bottom: 50px;
    margin-left: 25px;
    margin-right: 25px;
  }
  .about-us-img {
    height: 120px;
    width: 100%;
  }
  .about-us-text {
    font-size: 11px;
    line-height: 17px;
  }
}
