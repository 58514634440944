@media only screen and (min-width: 1030px) {
  .product-grid-card :hover {
    transform: scale(1.05);
    transition: 0.5s;
  }
}

.product-grid-title {
  height: 50px;
}

.grid-img {
  max-width: 100%;
  max-height: 100%;
}

.product-grid-card {
  border: 1px solid #494949;
}
