.bg-dark {
  background-color: #000000 !important;
}

.custom-navbar {
  background-color: #000000 !important;
}

.navbar-mobile {
  display: none;
}

.navbar-pc {
  display: block;
}

.logo-img {
  width: 180px;
}

.nav-text-pc {
  font-size: 13px;
  color: #b58f6c;
  width: 75px;
  height: 75px;
  border-radius: 100%;
  font-weight: 500;
  border: 3px solid #b58f6c;
}

.nav-text-pc:hover {
  color: #000000;
  background-color: #b58f6c;
}

.badge {
  padding-left: 10px;
  padding-right: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

#lblCartCount {
  font-size: 10px;
  background: #b58f6c;
  color: #000000;
  padding: 5px 8px;
  vertical-align: top;
  margin-left: -10px;
  margin-top: -27px;
}

.nav-text-pc:hover #lblCartCount {
  background: #000000;
  color: #b58f6c;
}

.navbar-nav .active {
  color: #000000;
  background-color: #b58f6c;
}

.navbar-nav .active #lblCartCount {
  background: #000000;
  color: #b58f6c;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  box-shadow: none;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 990px) {
  .logo-img {
    width: 150px;
  }
  .navbar-mobile {
    display: block;
  }
  .navbar-pc {
    display: none;
  }

  .nav-text-mobile {
    font-size: 15px;
    color: #b58f6c;
    height: 50px;
  }

  .nav-text-mobile:hover {
    color: #b58f6c;
    background-color: transparent;
  }

  #lblCartCount {
    font-size: 10px;
    background: #b58f6c;
    color: #000000;
    padding: 5px 8px;
    vertical-align: top;
    margin-left: -10px;
    margin-top: -7px;
  }
}

@media only screen and (max-width: 768px) {
  .logo-img {
    width: 120px;
  }
  .navbar-mobile {
    display: block;
  }
  .navbar-pc {
    display: none;
  }

  .nav-text-mobile {
    font-size: 12px;
    color: #b58f6c;
    height: 30px;
  }

  .nav-text-mobile:hover {
    color: #b58f6c;
    background-color: transparent;
  }

  #lblCartCount {
    font-size: 10px;
    background: #b58f6c;
    color: #000000;
    padding: 5px 8px;
    vertical-align: top;
    margin-left: -10px;
    margin-top: -7px;
  }
}
