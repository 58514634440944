.acc-top-bar-pc,
.acc-top-bar-mobile {
  border-top: 1px solid #494949;
  border-bottom: 1px solid #494949;
}

.nav-pills .nav-link {
  border-radius: 0px !important;
}

.nav-pills .nav-link .nav-arrow {
  display: none;
}

.nav-pills .nav-link.active .nav-arrow {
  display: inline-block;
}

.nav-link {
  color: #6f5c47;
}

.nav-link:hover {
  color: #b58f6c;
}

.nav-pills .nav-link.active {
  color: #b58f6c;
  background-color: transparent;
}

.account-tab-container {
  border: 1px solid #494949;
}

.account-tab-title {
  font-weight: 500;
  border-bottom: 1px solid #494949;
}

.logout-msg-container {
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .logout-msg-container {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .acc-top-bar-pc {
    display: flex !important;
  }

  .acc-top-bar-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 640px) {
  .small-screen-text {
    font-size: 11px;
  }
}

@media only screen and (max-width: 768px) {
  .acc-top-bar-pc {
    display: none !important;
  }

  .acc-top-bar-mobile {
    display: flex !important;
    flex-direction: column;
  }
}
