.cart-img {
  width: 150px;
}

.cart-product-card {
  position: relative;
}

.cartProduct-cross {
  position: absolute;
  right: 0px;
  width: auto;
}

.qty-num {
  outline: none;
}
