#products-slider .slick-track {
  background-color: #000000;
}

.slider-container {
  width: 100%;
  background-color: #000000;
  border-right: 1px solid #2a2a2a;
  border-left: 1px solid #2a2a2a;
}

.slider-image {
  width: 70% !important;
}

.slick-prev,
.slick-next {
  background-color: #d7c1a9;
  padding: 1px 1px;
  height: 25px;
  width: 25px;
  color: #000000;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #d7c1a9;
  padding: 1px 1px;
  color: #000000;
}

.products-slider-link {
  text-decoration: none;
}
