.pagination {
  display: flex;
  flex-wrap: wrap;
  line-height: 50px;
  list-style: none;
  outline: none;
  margin: 10px;
  margin-right: 0px;
  /* padding: 5px; */
  justify-content: space-evenly;
  float: right;
}
.pagination > .active > a {
  background-color: #b58f6c;
  color: #fff;
  /* margin: 10px; */
}
.pagination > li > .disabled {
  color: #b58f6c;
  border: 1px solid #b58f6c;
}
.pagination > li > a {
  background-color: transparent;
  padding: 4px 12px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > span:hover {
  border: 1 px solid #b58f6c;
}
.pagination_link--disabled {
  display: none;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  margin: 0px 5px;
  background-color: #b58f6c;
  color: white !important;
  border-color: #b58f6c;
  outline: none;
  border: 1px solid#B58F6C;
}

.pagination > li > a,
.pagination > li > span {
  color: #b58f6c;
  text-decoration: none;
  font-size: 14px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  border: 1px solid #b58f6c;
  color: #b58f6c;
  margin: 0px 5px;
}
