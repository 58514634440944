.modal-btn {
  width: 100%;
  font-size: 15px;
}
/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #0000 !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important; 
  transition: background-color 5000s ease-in-out 0s !important;
} */