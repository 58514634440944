.react-tabs__tab-list {
  border-bottom: 1px solid #494949;
}

.react-tabs__tab {
  border: none;
  color: #b58f6c;
}

.react-tabs__tab--selected {
  background-color: transparent;
  border-bottom: 5px solid #b58f6c !important;
}

.react-tabs__tab:focus:after {
  background-color: transparent;
}

.react-tabs__tab {
  font-size: 25px;
  font-weight: 600;
}

@media only screen and (max-width: 640px) {
  .react-tabs__tab {
    font-size: 15px;
    font-weight: 600;
  }
}
