html,
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background-color: #2a2a2a;
}

@media only screen and (max-width: 600px) {
  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){

  html,
  body {
    width: 100%;
    overflow-x: hidden;
  }
}