.form-input {
  background-color: #2a2a2a;
  border: 1px solid #494949;
  color: #b58f6c;
  padding: 5px 10px;
  width: 100%;
}

.loginsignup-form-container {
  margin-top: 30px;
  border: 1px solid #494949;
  padding: 20px 40px;
  width: 550px;
}

.forgot-password-btn {
  background-color: transparent;
  color: #6f5e4e;
}

.forgot-password-btn:hover {
  color: #b58f6c;
  transition: 0.5s;
}

@media only screen and (min-device-width: 800px) and (max-device-width: 1240px) {
  .loginsignup-form-container {
    width: 450px;
  }
}

@media only screen and (min-device-width: 700px) and (max-device-width: 800px) {
  .loginsignup-form-container {
    width: 700px;
  }
}
