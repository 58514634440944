.list-text {
  color: #7a6048;
  transition: 0.33s;
}

.list-text:hover {
  color: white;
  transition: 0.33s;
  background-color: #b58f6c;
}

.grid-list-btn {
  background: transparent;
  color: #b58f6c;
  border: 1px solid #b58f6c;
  border-radius: 0px;
  padding: 10px 20px;
}

.grid-list-btn:hover {
  color: #ffffff;
  background-color: #b58f6c;
  border: 1px solid #b58f6c;
  border-radius: 0px;
  padding: 10px 20px;
}

.active-layout-btn {
  background-color: #b58f6c;
  color: white;
}

.img-container1 {
  width: 180px;
  height: 120px;
}

.view-in-largeScreen {
  display: block;
}

.view-in-smallScreen {
  display: none;
}

.dropdown-toggle {
  border-radius: 0px;
  color: #ffffff;
  background-color: #b58f6c;
  border: none;
}

.dropdown-menu.show {
  border-radius: 0px;
  border: 1px solid #494949;
}

@media only screen and (max-width: 640px) {
  .img-container1 {
    width: 150px;
    height: 90px;
  }

  .view-in-largeScreen {
    display: none;
  }

  .view-in-smallScreen {
    display: block;
  }

  .dropdown-toggle {
    border-radius: 0px;
    color: #ffffff;
    background-color: #b58f6c;
    border: none;
  }

  .dropdown-toggle:hover {
    background-color: #b58f6c;
    color: #ffffff;
  }

  .dropdown-toggle .btn:first-child:active {
    background-color: #b58f6c;
  }

  .dropdown-menu.show {
    border-radius: 0px;
    border: 1px solid #494949;
  }
}
