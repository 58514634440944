.cart-container {
  border: 1px solid #494949;
}

.cart-empty-msg {
  font-size: 18px;
  font-weight: 500;
}

.innerPage-container {
  min-height: 700px;
}

.cart-top {
  border-bottom: 1px solid #494949;
}

.cart-product-card {
  border-bottom: 1px solid #494949;
}

@media only screen and (min-device-width: 768px) {
  .cart-title {
    display: flex;
    border-bottom: 1px solid #494949;
  }
}

@media only screen and (max-width: 768px) {
  .cart-title {
    display: none;
    border-bottom: 1px solid #494949;
  }
}

@media only screen and (max-width: 600px) {
  .cart-empty-msg {
    font-size: 11px;
  }
}
