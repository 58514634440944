/* .mission-text-container {
  max-width: 600px;
} */

.mission-text {
  line-height: 24px;
}

.basic-btn {
  font-size: 12px;
  background-color: #b58f6c;
  color: white;
  padding: 10px 20px;
}

.basic-btn:hover {
  background-color: transparent !important;
  border: 1px solid #b58f6c;
  color: #b58f6c !important;
}

.basic-btn:focus {
  background-color: #b58f6c;
  color: white;
}

@media only screen and (max-device-width: 600px) {
  .mission-text {
    font-size: 12px !important;
  }

  .mission-text {
    line-height: 17px;
  }
}
