.latest-section {
  height: 650px;
  background-color: #191919;
}

.latest-slider-image {
  /* width: 400px; */
  width: 90%;
  height: 200px;
}

.latest-slider-container {
  width: 100%;
  max-height: 100%;
  position: relative;
}

.latest-slider-link {
  text-decoration: none;
}

.New__Slider_Img {
  height: 266px !important;
  width: 80%;
}




/* repositioning the  arrows */

.latest-section .slick-prev {
  position: absolute !important;
  z-index: 3 !important;
  /* left: -10px !important; */
}

.latest-section .slick-next {
  position: absolute !important;
  z-index: 3 !important;
  /* right: -10px; */
}

/* ////////////////////////// */


@media only screen and (min-device-width: 900px) and (max-device-width: 1024px) {
  .latest-section {
    height: 500px;
  }

  .latest-section .slick-prev {
    left: -12px !important;
  }

  .latest-section .slick-next {
    right: -12px !important;
  }

  .latest-slider-container {
    width: 90% !important;
    max-height: 90% !important;

  }
}
@media only screen and (max-width: 600px) {
  .latest-section .slick-prev {
    left: -12px !important;
  }

  .latest-section .slick-next {
    right: -12px !important;
  }

  /* .latest-slider-container {
    width: 90% !important;
    max-height: 90% !important;

  } */
 
 
}