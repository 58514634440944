.customer-slider-container {
  background-color: #191919;
  height: 500px;
}

.customer-image {
  width: 120px;
}

@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) {
  .customer-slider-container {
    height: 550px;
  }
}

@media only screen and (max-width: 767px) {
  .customer-slider-container {
    height: 700px;
  }
}

@media only screen and (max-width: 500px) {
  .testimonial-text {
    font-size: 13px;
  }
}
