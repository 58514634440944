.App {
  background-color: #2a2a2a;
}

.ELPi-text-color {
  color: #b58f6c;
}

.ELPi-heading {
  font-weight: 400;
}

.app-container {
  --bs-gutter-x: 0;
}

.img-container1 {
  width: 180px;
  height: 120px;
}

.img-container2 {
  max-width: 380px;
  max-height: 240px;
}

.page-container {
  padding-top: 95px;
}

.page-container-login-signup {
  padding-top: 95px;
}

/* ///////////////reset input///////////////// */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* input:-internal-autofill-selected {
  background-color: #b58f6c !important;
  color: white !important;
} */
/* input:-webkit-autofill{
  -webkit-text-fill-color: white !important;
 background-color: #b58f6c;
} */
/* input:-internal-autofill-selected{
  background: #b58f6c !important;
  color: white !important;
} */

/* input[type=text]:focus {
  background-color: transparent !important;
  color: white !important;
} */



/* ////////////////////////////////////////// */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .page-container {
    padding-top: 90px;
  }
  .page-container-login-signup {
    padding-top: 90px;
  }
}

@media only screen and (max-width: 768px) {
  .page-container {
    padding-top: 67px;
  }
  .page-container-login-signup {
    padding-top: 67px;
  }
}

@media only screen and (max-width: 600px) {
  .mobile-padding {
    margin-left: 25px;
    margin-right: 25px;
  }
  .page-container-login-signup {
    padding-top: 67px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
