.get-in-touch-form {
  background-color: #b58f6c;
}

.form-input-contact {
  background-color: transparent;
  border-radius: 0px;
  border: 1px solid #ffffff;
  color: #ffffff;
}

.contact-btn {
  width: 100%;
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: transparent;
  border-radius: 0;
}

.contact-btn:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.form-input-contact::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #e2caaf;
  opacity: 1; /* Firefox */
}

.form-input-contact:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #e2caaf;
}

.form-input-contact::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #e2caaf;
}

.form-input-contact {
  font-size: 13px;
}
/* ::selection {
  background-color: e2caaf; 
  color: white;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #b58f6c inset;
  transition: background-color 5000s ease-in-out 0s;
}
