.loading-container {
  background: transparent;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
}

.spinner-grow {
  color: #b58f6c;
}
