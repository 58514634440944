.language-toggle {
  position: absolute;
  z-index: 99;
  right: 50px;
}

#lang-switch img {
  width: 32px;
  height: 32px;
  opacity: 0.5;
  transition: all 0.5s;
  margin: auto 3px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#lang-switch img:hover {
  cursor: pointer;
  opacity: 1;
}

.active-flag {
  transition: all 0.5s;
  opacity: 1 !important;
}

.language-toggle .form-check-input:checked {
  background-color: #b58f6c;
  border-color: #b58f6c;
}

@media only screen and (max-device-width: 1600px) {
  .language-toggle {
    right: 650px;
  }
}

@media only screen and (max-device-width: 1500px) {
  .language-toggle {
    right: 550px;
  }
}

@media only screen and (max-device-width: 1400px) {
  .language-toggle {
    right: 650px;
  }
}

@media only screen and (max-device-width: 1270px) {
  .language-toggle {
    right: 650px;
  }
}

@media only screen and (max-device-width: 1111px) {
  .language-toggle {
    right: 550px;
  }
}

@media only screen and (max-device-width: 950px) {
  .language-toggle {
    right: 270px;
    top: 30px;
  }
}

@media only screen and (max-device-width: 768px) {
  .language-toggle {
    right: 250px;
    top: 25px;
  }
}

@media only screen and (max-device-width: 700px) {
  .language-toggle {
    right: 200px;
    top: 22px;
  }
  .hide-in-mobile {
    display: none;
  }
}

@media only screen and (max-device-width: 640px) {
  .language-toggle {
    right: 150px;
  }
}

@media only screen and (max-width: 500px) {
  .language-toggle {
    right: 120px;
  }
}
